import moment from "moment-timezone";
import MobileDetect from "mobile-detect";
import * as _ from 'lodash';

import * as StM from "../models/store";
import { Constants } from "../constants";
import { IUserStoreState, BookingStatus, ICustomSessionTypeStoreState } from "../models/store";
import { IInputSearchItem } from "../components/inputSearch";

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match: any, number: any) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

export interface ITimeObject {
  time: string;
  format: string;
}

export class Utils {
  private static _instance: Utils;
  private static timezoneOffset: string;

  constructor() {
    if (typeof Utils._instance == "undefined") {
      Utils._instance = this;
    }
    return Utils._instance;
  }

  public getUtcInClubTimeZone(datetime: moment.Moment, club: StM.IClubStoreState, skipCache: boolean = false): moment.Moment {
    const utcOffsetValue = this.getClubUtcOffset(club, datetime, skipCache);
    const utcOffset = moment.duration(utcOffsetValue).add(club.offsetInHours, 'hours');
    return datetime.clone().add(utcOffset);
  }

  public getClubUtcOffset(club: StM.IClubStoreState, datetime: moment.Moment = moment.utc(), skipCache: boolean = false): string {
    if (club && club.aliasedTimeZone && club.aliasedTimeZone.timeZoneInfo) {
      if (datetime && skipCache) {
        const dInTimeZone = moment.tz(datetime.format(Constants.DateTime.API_FORMAT),club.aliasedTimeZone.dbName);
        return moment.tz(dInTimeZone, club.aliasedTimeZone.dbName).format('Z');
      }
      if (skipCache) {
        return moment.tz(club.aliasedTimeZone.dbName).format('Z');
      }
      if (!club.timeZoneOffset) {
        club.timeZoneOffset = moment.tz(club.aliasedTimeZone.dbName).format('Z');
      }
        
      return club.timeZoneOffset;
    }
    if (!Utils.timezoneOffset){
      Utils.timezoneOffset = moment.tz('America/New_York').format('Z');
    } 
    return Utils.timezoneOffset;
  }

  public getCurrentClubTime(club: StM.IClubStoreState): moment.Duration {
    return moment.duration(this.getCurrentClubDateTime(club).format('H:mm'));
  }

  public getCurrentClubDateTime(club: StM.IClubStoreState): moment.Moment {
    const time = moment(
      moment()
        .utc()
        .format(Constants.DateTime.API_FORMAT),
      Constants.DateTime.API_FORMAT
    );
    return this.getUtcInClubTimeZone(time, club);
  }

  get_name_browser() {
    var ua = navigator.userAgent;
    if (ua.search(/Chrome/) > 0) return "Google Chrome";
    if (ua.search(/Firefox/) > 0) return "Firefox";
    if (ua.search(/Opera/) > 0) return "Opera";
    if (ua.search(/Safari/) > 0) return "Safari";
    if (ua.search(/MSIE/) > 0) return "Internet Explorer";
    return "undefined";
  }

  getIsOpenDemographicInfoDialog(user: StM.IUserStoreState): boolean {
    return (
      !user.dateOfBirth ||
      !user.gender ||
      !user.skill ||
      !user.emergencyContactFullName ||
      !user.emergencyContactPhoneNumber ||
      !user.emergencyContactRelationship
    );
  }

  getDateFormat(datetime: moment.Moment): string {
    const format = datetime.format(Constants.DateTime.DATE_FORMAT);
    return format;
  }



  getPageSessions(state: StM.IGlobalStoreState) {
    let sessions: Array<StM.ISessionStoreState> = [];
    return sessions.concat(
      state.pages.book.sessions,
      state.basket.goods,
      state.pages.mySessions.sessions,
      state.pages.seekingPlayerBoard.sessions
    );
  }

  getPagePayments(state: StM.IGlobalStoreState) {
    let payments: Array<StM.IUserPaymentStoreState> = [];
    return payments.concat(state.pages.history.payments);
  }

  public isActiveBooking(session: StM.ISessionStoreState, booking: StM.IBookingStoreState, excludeNewBookings = false, isForPriceCalculations = false, includeLateCancel: boolean = false): boolean {
    if(!booking) return false;
    const activeStatuses = [
      StM.BookingStatus.CheckedOut,
      StM.BookingStatus.CheckedIn,
      StM.BookingStatus.Paid,
      StM.BookingStatus.PayFail,
      StM.BookingStatus.NoShow,
      StM.BookingStatus.NoShowPayError
    ];
    if(includeLateCancel || isForPriceCalculations && session && session.type !== StM.SessionType.Private && !session.splitPrice)
    {
      activeStatuses.push(StM.BookingStatus.LateCancel);
      activeStatuses.push(StM.BookingStatus.LateCancelPayFail);
    }

    if(!excludeNewBookings) {
      activeStatuses.push(StM.BookingStatus.New);
    }

    return _.includes(activeStatuses, booking.status);
  }

  public isCountableSplitBooking(booking: StM.IBookingStoreState): boolean {
    if(!booking) return false;
    const activeStatuses = [
      StM.BookingStatus.CheckedOut,
      StM.BookingStatus.CheckedIn,
      StM.BookingStatus.Paid,
      StM.BookingStatus.PayFail,
      StM.BookingStatus.NoShow,
      StM.BookingStatus.NoShowPayError
    ];
    return _.includes(activeStatuses, booking.status);
  }

  public getSessionTitle(
    session: StM.ISessionStoreState,
    showDouble: boolean = true,
    markAsSession: boolean = true,
  ) {
    let result = '';
    if (!session) return result;
    
    result = session.type == StM.SessionType.Custom
      ? session.title || `Custom ${markAsSession ? 'Session' : ''}`
      : `${this.getSessionTypeTitle(session, markAsSession)}${session.title ? ': ' + session.title : ''}`;

    if (showDouble && session.isDoubledSession) result = `Double ${result}`;
    
    return result;
  }

  public getSessionTypeTitle(session: StM.ISessionStoreState, markAsSession: boolean = true) {
    return session ? this.getSessionTypeTitleByType(session.type, markAsSession) : '';
  }

  public getSessionTypeTitleByType(sessionType: StM.SessionType, markAsSession: boolean = true) {
    if (!sessionType) return '';
    let canBeMarkedAsSession = true;
    let result = '';
    switch (sessionType) {
      case StM.SessionType.Play:
        result = 'Play';
        break;
      case StM.SessionType.Clinic:
        result = 'Clinic';
        canBeMarkedAsSession = false;
        break;
      case StM.SessionType.Private:
        result = 'Lesson';
        canBeMarkedAsSession = false;
        break;
      case StM.SessionType.Custom:
        result = 'Custom';
        break;
      default: 
        result = 'Other';
        break;
    }
    if (canBeMarkedAsSession && markAsSession) result += ' Session';
    return result;
  }

  public getSessionTypeTitleById(packageSessionTypeId: number, sessionTypes: Array<any>) {
    return (foundSessionType => foundSessionType ? foundSessionType.name : '')(sessionTypes.find(type => type.value === packageSessionTypeId));
  }

  public getSessionClass(session: StM.ISessionStoreState) {
    if (!session) return "";
    return this.getSessionClassByType(session.type);
  }

  public getSessionClassByType(sessionType: StM.SessionType, customSessionType?: ICustomSessionTypeStoreState) {
    if (!sessionType) return "";
    if (customSessionType) return customSessionType.title;

    switch (sessionType) {
      case StM.SessionType.Play: {
        return "play";
      }
      case StM.SessionType.Clinic: {
        return "clinic";
      }
      case StM.SessionType.Private:
      case StM.BookPageSessionType.Lesson: {
        return "lesson";
      }
      case StM.SessionType.Custom: {
        return "custom";
      }
      default: {
        return "play";
      }
    }
    
    
    
  }

  public getSessionTypeByFilterType(
    filterType: StM.BookPageSessionType
  ): string {
    switch (filterType) {
      case StM.BookPageSessionType.Play: {
        return StM.SessionType.Play;
      }
      case StM.BookPageSessionType.Lesson: {
        return StM.SessionType.Private;
      }
      case StM.BookPageSessionType.Clinic: {
        return StM.SessionType.Clinic;
      }
      case StM.BookPageSessionType.Custom: {
        return StM.SessionType.Custom;
      }
    }
    return StM.SessionType.Play;
  }

  public getFilterTypeBySessionType(sessionType: string): string {
    switch (sessionType) {
      case StM.SessionType.Play: {
        return StM.BookPageSessionType.Play;
      }
      case StM.SessionType.Private: {
        return StM.BookPageSessionType.Lesson;
      }
      case StM.SessionType.Clinic: {
        return StM.BookPageSessionType.Clinic;
      }
      case StM.SessionType.Custom: {
        return StM.BookPageSessionType.Custom;
      }
      default: {
        return "";
      }
    }
  }

  public getSessionType(
    session: StM.ISessionStoreState,
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState> = []
  ) {
    if (!session) return "";
    let result = "";
    let type = session.type;
    switch (type) {
      case StM.SessionType.Play: {
        result = "Play";
        break;
      }
      case StM.SessionType.Clinic: {
        result = "Clinic";
        break;
      }
      case StM.SessionType.Private: {
        result = "Lesson";
        break;
      }
      case StM.SessionType.Custom: {
        result = this.getCustomSessionTypeTitleByAlias(
          session.typeAlias,
          customSessionTypes
        );
        break;
      }
      default: {
        result = "Other";
      }
    }
    return result;
  }

  public getCustomSessionTypeTitleByAlias(
    typeAlias: string,
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>
  ) {
    if (
      !!typeAlias &&
      !!customSessionTypes &&
      customSessionTypes.length &&
      customSessionTypes.length > 0
    ) {
      const type = _.find(customSessionTypes, { alias: typeAlias });
      if (type) return type.title;
    }
    return _.startCase(typeAlias);
  }

  public getReadableBookingStatus(status: string) {
    switch (status) {
      case StM.BookingStatus.CheckedOut:
        return "Pending Payment";
      case StM.BookingStatus.CheckedIn:
        return "Checked In";
      case StM.BookingStatus.Paid:
      case StM.BookingStatus.NoShow:
        return "Paid";
      case StM.BookingStatus.LateCancel:
        return "Cancelled Late";
      case StM.BookingStatus.PayFail:
      case StM.BookingStatus.LateCancelPayFail:
      case StM.BookingStatus.NoShowPayError:
        return "Payment Failure";
      default: return  status;
    }
  }

  public getReadablePaymentMethod(type: string) {
    return !!StM.PaymentSystemType[type] ? `${type}: Charge` : type;
  }

  public getIsValidEmail(email: string) {
    if (!email) {
      return false;
    } else {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        return false;
      }
    }
    return true;
  }

  public formatStringPrice(price: string) {
    let num = parseFloat(price);
    return Math.ceil(num) - num > 0
      ? Number(Math.round(parseFloat(num.toString() + "e2")) + "e-2").toFixed(2)
      : num.toString();
  }

  public getMailToLink(
    addressee: string,
    cc: string,
    subject: string,
    body: string
  ) {
    let isFirst = true;
    const getDelimeter = () => {
      if (isFirst) {
        isFirst = false;
        return "?";
      }
      return "&";
    };

    let link = "mailto:{0}".format(addressee || "");
    if (cc) {
      link += "{0}cc={1}".format(getDelimeter(), cc);
    }
    if (subject) {
      link += "{0}subject={1}".format(getDelimeter(), subject);
    }
    if (body) {
      link += "{0}body={1} ".format(getDelimeter(), encodeURIComponent(body));
    }

    return link;
  }

    public getPostOnFacebookLink(sharedLink: string) {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedLink)}`;
    }

  public getIsFuture(
    club: StM.IClubStoreState,
    currentDate: moment.Moment,
    currentTime: moment.Duration
  ): boolean {
    let isFuture = false;
    const nowDate = this.getUtcInClubTimeZone(moment.utc(), club);
    const nowTime = moment.duration(nowDate.format("H:mm"));
    const dayDiff = currentDate.diff(nowDate, "days");
    if (currentDate.year() != nowDate.year())
      return currentDate.year() > nowDate.year();
    if (
      dayDiff == 0 &&
      currentDate.date() == nowDate.date() &&
      currentTime.asHours() > nowTime.asHours()
    ) {
      isFuture = true;
    } else if (
      dayDiff == 0 &&
      ((currentDate.month() === nowDate.month() &&
        currentDate.date() > nowDate.date()) ||
        currentDate.month() > nowDate.month() ||
        currentDate.year() > nowDate.year())
    ) {
      isFuture = true;
    } else if (dayDiff > 0) {
      isFuture = true;
    }
    return isFuture;
  }

  public handlePhoneFormattedChanging(
    e: any,
    phone: string,
    lastKey: number
  ): { cursorPosition: number; phone: string } {
    let cursorPosition = e.target.selectionStart;
    let isRemoveNumber = e.target.value.length < phone.length;
    let removedCharacters = phone.length - e.target.value.length;
    let isSpecSimbol = isRemoveNumber && !/\d|^$/.test(phone[cursorPosition]);
    let formattedPhone = this.formatPhone(e.target.value);

    if (isRemoveNumber && isSpecSimbol) {
      if (lastKey == 46) {
        //// 46 = delete, 8 = backspace.
        let char = formattedPhone[++cursorPosition] || "";
        while (!/\d|^$/.test(char)) {
          cursorPosition++;
          char = formattedPhone[cursorPosition] || "";
        }
      } else {
        let char = phone[cursorPosition - 1] || "";
        while (!/\d|^$/.test(char)) {
          cursorPosition--;
          char = phone[cursorPosition - 1] || "";
        }
      }
    } else if (!isRemoveNumber) {
      cursorPosition--;
      let char = formattedPhone[cursorPosition] || "";
      while (!/\d|^$/.test(char)) {
        cursorPosition++;
        char = formattedPhone[cursorPosition] || "";
      }
      cursorPosition++;
    } else if (removedCharacters > 1) {
      cursorPosition = formattedPhone.length;
    }
    return {
      cursorPosition: cursorPosition,
      phone: formattedPhone
    };
  }

  public formatPhone(phone: string): string {
    let number = phone.replace(/(\D)+/g, "");
    // taken from https://en.wikipedia.org/wiki/List_of_country_calling_codes
    let one = /^(1|7)/.test(number);
    let three = /^(2[^07]|35|37|38|42|50|59|67|68|69|80|85|87|88|96|97|99)/.test(
      number
    );
    let regExp = one
      ? /^(\d)?(\d{1,3})?(\d{1,3})?(\d{1,4})?.*/
      : three
      ? /^(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,6})?.*/
      : /^(\d{1,2})?(\d{1,3})?(\d{1,3})?(\d{1,7})?.*/;

    var formattedNumber = number
      ? one
        ? number.replace(regExp, "+$1 ($2) $3 $4")
        : number.replace(regExp, "+$1 $2$3$4")
      : "";
    return formattedNumber.replace(/([ ()])*$/, "");
  }

  public getIsValidPhone(phone: string): boolean {
    //let one = /^\+(1|7)/.test(phone);
    // let regExp = one
    //     ? /^\+\d \(\d{3}\) \d{3}-\d{4}$/
    //     : /^(.){10,20}$/;
    let regExp = /^(.){10,20}$/;
    return regExp.test(phone);
  }

  public isMobile() {
    const userAgent = navigator.userAgent;
    const mobileDetector = new MobileDetect(userAgent);

    const isIpad = navigator.maxTouchPoints && navigator.maxTouchPoints >= 1 && /Macintosh/.test(navigator.userAgent);
    const mobile = mobileDetector.mobile();
    const tablet = mobileDetector.tablet() || isIpad;

    return mobile || tablet;
  }

  public getCoachTierTitleById(
    coachFeeTierId: number,
    coachTiers: Array<StM.ICoachFeeTierStoreState>
  ) {
    const coachTier = coachTiers.find(coach => coach.id === coachFeeTierId);

    return coachTier ? coachTier.title : null;
  }

  public getOrderedCoaches(
    coaches: Array<StM.ICoachStoreState>,
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>,
    coachesAvailabilityTimesLookUp?: IDictionary,
    date?: string,
    comparator: Array<string> = ["tier", "name"],
    order: Array<'asc' | 'desc'> = ["asc", "asc"]
  ) {
    const cfts = _.filter(
      coachFeeTiers,
      (coachFeeTier: StM.ICoachFeeTierStoreState) => {
        return coachFeeTier.isShowForUser && !coachFeeTier.isNoCoach;
      }
    );

    const orderedCoaches = _.orderBy(
      _.map(
        _.filter(
          _.uniqBy(coaches, "id"),
          f =>
          _.some(cfts, at => at.id == f.coachFeeTierId)
            
        ),
        c => {
          return {
            coach: c,
            name: c.displayName.toUpperCase(),
            tier:
              _.find(cfts, t => t.id == c.coachFeeTierId)
                .orderNumber || 0,
            availableTime:
              coachesAvailabilityTimesLookUp &&
              coachesAvailabilityTimesLookUp[c.id] &&
              coachesAvailabilityTimesLookUp[c.id][date]
                ? coachesAvailabilityTimesLookUp[c.id][date]
                : 0
          };
        }
      ),
      comparator,
      order
    );

    return _.map(orderedCoaches, "coach") as Array<StM.ICoachStoreState>;
  }

  public getOrderedCoachesDesktop(
    coaches: Array<StM.ICoachStoreState>,
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>,
    coachesAvailabilityTimesLookUp?: IDictionary,
    date?: string,
    comparator: Array<string> = ["availableTime", "tier", "name"],
    order: Array<'desc' | 'asc'> = ["desc", "asc", "asc"]
  ) {
    return this.getOrderedCoaches(
      coaches,
      coachFeeTiers,
      coachesAvailabilityTimesLookUp,
      date,
      comparator,
      order
    );
  }

  public getOrderedCoachesMobile(
    coaches: Array<StM.ICoachStoreState>,
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>,
    coachesAvailabilityTimesLookUp?: IDictionary,
    date?: string,
    comparator: Array<string> = ["tier", "availableTime", "name"],
    order: Array<'desc' | 'asc'> = ["asc", "desc", "asc"]
  ) {
    return this.getOrderedCoaches(
      coaches,
      coachFeeTiers,
      coachesAvailabilityTimesLookUp,
      date,
      comparator,
      order
    );
  }

  public getDefaultCoach(
    coaches: Array<StM.ICoachStoreState>,
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>
  ): StM.ICoachStoreState {
    const defaultCft = _.find(coachFeeTiers, { isDefaultForUser: true });
    if (defaultCft) {
      let cftCoaches = _.filter(coaches, (coach: StM.ICoachStoreState) => {
        return (
          coach.coachFeeTierId === defaultCft.id
        );
      });
      cftCoaches = _.orderBy(cftCoaches, (cftCoach: StM.ICoachStoreState) => {
        return cftCoach.displayName.toUpperCase();
      });
      const defaultCoach = cftCoaches ? cftCoaches[0] : null;
      return defaultCoach;
    }
    return null;
  }

  public checkIsSessionFiltered(
    session: StM.ISessionStoreState,
    filter: StM.ISchedulePageFilter
  ) {
    if (session == null) return false;
    if (!filter) return false;

    let isSessionTypeFiltered = true;
    let isCoachFiltered = true;
    let isSkillFiltered = true;
    let isAttendenceFiltered = true;

    if (filter.ignoredSessionTypes.size) {
      const sessionType = (session.type === StM.SessionType.Private 
        ? StM.SchedulePageSessionTypes.Lesson
        : session.type).toLowerCase();
      isSessionTypeFiltered = !filter.ignoredSessionTypes.has(sessionType);
    }

    if (filter.ignoredCoaches.size && session.trainer) {
      isCoachFiltered = !filter.ignoredCoaches.has(session.trainer.id) ||
        (session.assistants.length && !session.assistants.some(a => filter.ignoredCoaches.has(a.id))); // todo: looks like a bug
    }

    if (filter.ignoredSkills.size) {
        const skill = !!session.playerQualification && session.playerQualification.skill;
        const isAdvanced = !filter.ignoredSkills.has(StM.UserSkill.Advanced);
        const isIntermediate = !filter.ignoredSkills.has(StM.UserSkill.Intermediate);
        const isBeginner = !filter.ignoredSkills.has(StM.UserSkill.Beginner);
        if (skill === StM.UserSkill.Advanced) {
            isSkillFiltered = isAdvanced;
        } else if (skill === StM.UserSkill.Intermediate) {
            isSkillFiltered = isIntermediate;
        } else if (skill === StM.UserSkill.Beginner) {
            isSkillFiltered = isBeginner;
        } else {
            isSkillFiltered = isAdvanced || isIntermediate || isBeginner;
        }
    }

    if (filter.ignoredAttendence.size) {
      const attendenceType =
        session.bookings && session.bookings.length
          ? StM.ScheduleAttendenceTypes.HaveParticipants
          : StM.ScheduleAttendenceTypes.NoParticipants;
      isAttendenceFiltered = !filter.ignoredAttendence.has(attendenceType);
    }

    return (
      isSessionTypeFiltered &&
      isSkillFiltered &&
      isCoachFiltered &&
      isAttendenceFiltered
    );
  }

  public getDurationTime(time: moment.Moment): moment.Duration {
    return moment.duration({
      hours: time.hours(),
      minutes: time.minutes(),
      seconds: time.seconds()
    });
  }

  public escapeRegExp(string: string) {
    return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  public isWithinLateCancellationFeePeriod(
    datetime: moment.Moment,
    club: StM.IClubStoreState
  ): boolean {
    if (!club) return false;
    const currentTime = this.getCurrentClubDateTime(club);
    return (
      moment(datetime.clone()).diff(moment(currentTime.clone()), "minutes") <=
      club.minimalCancellationPeriod * 60
    );
  }

  public checkIsBirthdayUnderage(dateOfBirth: moment.Moment, club: StM.IClubStoreState): boolean {
      const currentDate = moment(this.getCurrentClubDateTime(club).format(Constants.DateTime.API_FORMAT));
    
      let age = currentDate.year() - dateOfBirth.year();

      if(moment(dateOfBirth.format(Constants.DateTime.API_FORMAT)).isAfter(currentDate.startOf("day").add(-age, "year"))) --age;
      
      return age < Constants.MajorityAge;
  }

  public getSkill(skill: string) {
    let result = "";
    switch (skill) {
      case StM.UserSkill.Beginner:
        result = StM.DisplayUserSkill.Beginner;
        break;
      case StM.UserSkill.Intermediate:
        result = StM.DisplayUserSkill.Intermediate;
        break;
      case StM.UserSkill.Advanced:
        result = StM.DisplayUserSkill.Advanced;
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  public getShortSkill(skill: string) {
    let result = "";
    switch (skill) {
      case StM.DisplayUserSkill.Beginner:
        result = StM.UserSkill.Beginner;
        break;
      case StM.DisplayUserSkill.Intermediate:
        result = StM.UserSkill.Intermediate;
        break;
      case StM.DisplayUserSkill.Advanced:
        result = StM.UserSkill.Advanced;
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  public isCoach(profile: IUserStoreState): boolean {
    if (!profile || !profile.permissions || !profile.permissions.length)
      return false;
    return _.includes(
      [StM.Roles.Coach, StM.Roles.CoachAdmin],
      profile.permissions[0].role
    );
  }

  public promiseSerial(funcs: Array<() => Promise<any>>): Promise<any> {
    return funcs.reduce(
      (promise, func) =>
        promise.then(result =>
          func().then(Array.prototype.concat.bind(result))
        ),
      Promise.resolve([])
    );
  }

  public hasVideo(
    session: StM.ISessionStoreState,
    user: StM.IPublicUserStoreState = null,
    checkBookingStatus: boolean = false
  ): boolean {
    return (
      (session.recordVideo || session.videoWasRecorded) && (!user ||
        (session.bookings &&
        _.some(session.bookings, (b: StM.IBookingStoreState) => {
          return (
            b.addons &&
            (!!user
              ? (!!b.user && b.user.id === user.id) || b.userId === user.id
              : true) &&
            (!checkBookingStatus || b.status === BookingStatus.CheckedIn) &&
            _.some(b.addons, (a: StM.AddonStoreState) => a.alias === "video")
          );
        })))
    );
  }

  public updateVideoAddon(
    session: StM.ISessionStoreState,
    isVideoToggleOn: boolean,
    waiveVideoFee: boolean = false
  ): void {
    if(session.type === StM.SessionType.Custom && !session.isPaidByOwner) return;
    const ownerId = (!!session.owner && session.owner.id) || session.ownerId;
    let bookingWithVideo = session.bookings.find((b) => ((b.user && b.user.id) || b.userId) === ownerId);
    if (isVideoToggleOn && !this.hasVideo(session, session.owner)) {
      if (!session.bookings) {
        session.bookings = [];
      }
      if (!bookingWithVideo) {
        bookingWithVideo = new StM.BookingStoreState();
        bookingWithVideo.user = session.owner;
        bookingWithVideo.userId = ownerId;
        bookingWithVideo.status = "New";
        session.bookings.push(bookingWithVideo);
      }
      if (!bookingWithVideo.addons) {
        bookingWithVideo.addons = [];
      }
     
    }

    if(bookingWithVideo && bookingWithVideo.addons) {
      let addon = _.find(bookingWithVideo.addons, (a) => a.alias == 'video');

      if (!addon) {
        addon = new StM.AddonStoreState();
        addon.alias = "video";
        addon.created = moment();
        bookingWithVideo.addons.push(addon);
      }
      addon.waiveFee = waiveVideoFee;
    }

    if (!!bookingWithVideo && !isVideoToggleOn && session.bookings ) {
      if (
        !!bookingWithVideo &&
        !!bookingWithVideo.addons &&
        bookingWithVideo.addons.length
      )
        _.remove(bookingWithVideo.addons, a => a.alias == "video");
    }
  }

  public updateSessionServices(session: StM.ISessionStoreState, services: StM.IAddonDefinitionStoreState[] = [], waiveFee: boolean = false) {
    if(session.type === StM.SessionType.Custom && !session.isPaidByOwner) return;
    const ownerId = (!!session.owner && session.owner.id) || session.ownerId;
    let bookingWithServices = session.bookings.find((b) => ((b.user && b.user.id) || b.userId) === ownerId);
    if(!bookingWithServices && services.length) {
      bookingWithServices = new StM.BookingStoreState();
      bookingWithServices.user = session.owner;
      bookingWithServices.userId = ownerId;
      bookingWithServices.status = StM.BookingStatus.New;
      session.bookings.push(bookingWithServices);
    }
    if(bookingWithServices) {
      if(!bookingWithServices.addons) bookingWithServices.addons = [];
      bookingWithServices.addons = services.map((s) => new StM.AddonStoreState({
        ...s,
        waiveFee,
        addonDefinition_Id: s.id,
      }));
    }
  }

  public getSessionServices(session: StM.ISessionStoreState, user: StM.IUserStoreState = null, checkVideoRecord: boolean = true) {
    const booking = !!session.bookings && session.bookings.find((b) => {
      const isActive = this.isActiveBooking(session, b);
      const hasAddons = !!b.addons && !!b.addons.length;
      const isUserChecked = !user || ((!!b.user && b.user.id === user.id) || b.userId === user.id);
      return isActive && hasAddons && isUserChecked;
    });

    const isAdmin = this.isAdmin();

    if (isAdmin && session.type === StM.SessionType.Custom && !session.isPaidByOwner && session.recordVideo){
      const addon = new StM.AddonDefinitionStoreState(); 
      addon.alias = StM.ServiceAlias.Video;
      return [addon];
    }

    return booking 
      ? booking.addons.reduce((result, a) => {
          if (a.alias !== StM.ServiceAlias.Other && (!checkVideoRecord || session.recordVideo)) {
            const addon = new StM.AddonDefinitionStoreState(a); 
            addon.id = a.addonDefinition_Id; 
            result.push(addon);
          }
          return result;
      }, [] as StM.IAddonDefinitionStoreState[])
      : [];
  }

  public videoIsAvailableForClub(addons: Array<StM.IAddonDefinitionStoreState>) : boolean {
    return !!addons && !!addons.length && addons.some((a) => a.alias === StM.ServiceAlias.Video && a.isActive);
  }

  public toDateOfBirth(date: moment.Moment): moment.Moment {
    return moment.utc({year: date.year(), month: date.month(), date: date.date()});
  }

  public getImageUrl(imageId: number): string {
    if (!imageId) return null;
    return Constants.ApiImagesPattern.format(imageId);
  }

  public getTimeObj(duration: moment.Duration): ITimeObject {
    let hours: any = duration.hours();
    let minutes: any = duration.minutes();
    let format = "am";
    if (+minutes < 10) {
      minutes = "0" + minutes;
    }
    if (+hours >= 12) {
      format = "pm";
    }
    if (+hours < 10) {
      hours = "0" + hours;
    }
    return {
      time: moment("" + hours + minutes, "hmm").format("h:mm"),
      format
    };
  }

  public getCourtTimeBlockTimesRange(
    courtTimeBlock: StM.ICourtTimeBlockStoreState,
    duration: number
  ): { start: ITimeObject; end: ITimeObject } {
    if (!courtTimeBlock) return null;
    const startTimeMs = moment.duration(courtTimeBlock.start.as("milliseconds"));
    return {
      start: this.getTimeObj(courtTimeBlock.start),
      end: this.getTimeObj(startTimeMs.add(duration, "m"))
    };
  }

  public getIsElementScrolledToBottom(el: any) {
    if (!el || !el.scrollTop || !el.scrollHeight || !el.offsetHeight){
      return false;
    }
    return Math.round(el.scrollTop) >= Math.round(el.scrollHeight - el.offsetHeight) - Math.round((el.offsetHeight/ 100) * 2);
  }

  public shortenString(
    source: string,
    maxLength: number = Constants.NameLength,
    clipPlaceholder: string = "..."
  ): string {
    if (source.length <= maxLength) return source;
    return (
      source.substring(0, maxLength - clipPlaceholder.length) +
      clipPlaceholder
    );
  }

  public getIsTouchDetected(): boolean {
    return 'ontouchstart' in window || 'ontouchstart' in document.documentElement;
  }

  public getCountriesInputSearchItems(source: Array<any>, value?: string): Array<IInputSearchItem> {
    if(!source || !source.length) return [];
    let result: Array<IInputSearchItem> = [];
    for (let i = 0; i < source.length; i++) {
      const item: IInputSearchItem = {
        key: i + 1,
        title: source[i].name,
        value: source[i].alpha2Code,
      };
      if(_.includes(['United States', 'US'], item.value)) {
        result.unshift(item);
      } else {
        result.push(item);
      }
    }
    return result;
  }

  public getReadableCourtTitle(source: string): string {
    const isNeedPrefix = /^([0-9])*(\s\(.*\)){0,1}$/.test(source);
    return `${isNeedPrefix ? `${StM.Strings.CourtPrefix} `  : ''}${source}`;
  }

  public getSessionCourtsTitle(session: StM.ISessionStoreState) {
    if(!session || (!session.court && !session.courts.length)) return null;
    const isMultiCourt = session.courts.length > 1;
    const title = isMultiCourt 
      ? this.getMultiCourtsTitle(session)
      : session.court ? session.court.title : session.courts[0].title;
    return `${StM.Strings.CourtPrefix}${isMultiCourt ? 's' : ''} ${title}`;
  }

  public getIconClass(icon: StM.Icon) {
    return `ic_${icon}`;
  }

  public getReadableSessionCourtsTitle(session: StM.ISessionStoreState) {
    if(!session) return null;
    const isMultiCourt = session.courts.length > 1;
    const title = isMultiCourt 
      ? this.getMultiCourtsTitle(session)
      : this.getReadableCourtTitle(session.court.title)
    return `${isMultiCourt ? `${StM.Strings.CourtPrefix}s ` : ''}${title}`;
  }

  public isAdmin() {
    const customWindow: any = window;
    return customWindow && customWindow.ISADMIN;
  }

  public getAvailablePricingTiers(tiers: StM.IPricingTierStoreState[]): StM.IPricingTierStoreState[] {
    return StM.AvailablePricingTiers.reduce((results, tierType) => {
      const tier = tiers.find(t => t.type === tierType && t.isEnabled);
      if (tier) results.push(tier);
      return results;
    }, []);
  }

  public handleMinPrice(price: number): number {
    return price > 0 && price < Constants.PaymentSystem.minTransactionAmount 
      ? Constants.PaymentSystem.minTransactionAmount 
      : price;
  }

  public isNumberValueInRange(value: number, min: number, max: number): boolean {
    return value >= min && value <= max;
  }

  public roundPrice(price: number): number {
    return Math.round(price * 100) / 100;
  }

  private getMultiCourtsTitle(session: StM.ISessionStoreState) {
    return session.courts.sort((a, b) => a.order - b.order).map(court => court.title).join(', ');
  }

  public isCanceledWithinPeriod(
    sessionStartDate: Date, 
    minimalCancellationPeriodHours: number, 
    clubTimeZone: string
  ): boolean {
    const sessionDate = moment.tz(sessionStartDate, clubTimeZone);
    const currentUserTime = moment.tz(clubTimeZone);
    const timeDifference = sessionDate.diff(currentUserTime, 'hours');
    
    return timeDifference < minimalCancellationPeriodHours;
  }

  public getCellClassNames(type: string) {
    const isHead = type === StM.GroupMemberType.Head;
    return isHead ? 'text-bold' : '';
  } 

  public copyTextToClipboard(textToCopy: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy);
    } else {
      // Fallback for old browsers
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  }

  public getPricingTier(date: moment.Moment, startTime: moment.Duration, pricingTiers: StM.IPricingTierStoreState[]) {
    return pricingTiers
        .sort((a, b) => b.priority - a.priority)
        .find((tier) => tier.schedule.some(schedule => this.getIsPricingTierPeriodEnabled(date, startTime, schedule)));
  }

  public getIsPricingTierPeriodEnabled(date: moment.Moment, startTime: moment.Duration, schedule: StM.IPricingTierPeriodStoreState) {
    const weekday = date.weekday();
    const startAsMinutes = startTime.asMinutes();
    let isDate = false;
    switch(weekday) {
        case 0: 
            isDate = schedule.isSunday;
            break;
        case 1: 
            isDate = schedule.isMonday;
            break;
        case 2: 
            isDate = schedule.isTuesday;
            break;
        case 3: 
            isDate = schedule.isWednesday;
            break;
        case 4: 
            isDate = schedule.isThursday;
            break;
        case 5: 
            isDate = schedule.isFriday;
            break;
        case 6: 
            isDate = schedule.isSaturday;
            break;
    }

    const isTime = schedule.start.asMinutes() <= startAsMinutes && startAsMinutes < schedule.end.asMinutes()

    return isDate && isTime;
  }
}
